
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































































.gallery-adjectives {
  ::v-deep .flexible-gallery__items {
    margin-top: var(--spacing-l);
    margin-bottom: var(--spacing-l);
  }
}

.gallery-adjectives__item__container {
  @include center-y;

  z-index: 1;
  width: 100%;
  height: var(--item-picture-height);
  background-color: rgba($c-white, 0.2);
}

.gallery-adjectives__item__adjective {
  position: absolute;
  display: flex;
  margin-left: -15vw;
  color: $c-dark-grey;
  font-family: $ff-alt;
  font-size: 2rem;
  font-weight: 700;

  &:nth-child(odd) {
    align-items: flex-start;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;
    align-items: flex-end;
  }

  @include mq(s) {
    margin-left: -10vw;
  }

  @include mq(m) {
    transform: translateX(var(--img-translate-x));
  }

  @include mq(l) {
    margin-left: 0;
  }
}

.gallery-adjectives__item__adjective__icon {
  fill: $c-mustard;
  margin: 0 math.div($spacing, 2);

  .gallery-adjectives__item__adjective:nth-child(odd) & {
    transform: rotate(180deg);
  }

  .gallery-adjectives__item__adjective.reverse & {
    transform: scaleY(-1) rotate(180deg) translateY(-30px);
  }
}
